#appointment {
	color: #fff;
	position: relative;
}
.appointment--background {
	position: absolute;
	z-index: -10;
	height: 100%;
	width: 100%;
}
.appointment--background img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: 50% 35%;
	object-position: 50% 35%;
}
#appointment p {
	margin: 0.5rem 0;
}
.appointment__time,
.appointment__price {
	position: relative;
	margin: 2rem;
}
.appointment--opacity {
	background-color: rgba(4, 3, 3, 0.6);
	padding: 2rem 0;
}
#appointment .time__details p {
	margin: 1rem 0;
	line-height: 2rem;
}
.appointment__location iframe {
	width: 90vw;
	height: 300px;
}

#appointment .price__details {
	margin: 2rem auto;
}
@media (max-width: 767px) {
	#appointment {
		margin: 3rem 0;
	}
}
@media (min-width: 768px) {
	#appointment {
		height: 100vh;
		font-size: 1.5rem;
	}
	.appointment--opacity {
		width: 80vw;
		transform: translateY(2rem);
		margin: 0 auto;
		background-color: rgba(4, 3, 3, 0.8);
	}
	.appointment__location iframe {
		width: 60vw;
		height: 400px;
	}
	.time__details,
	.price__details {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}
	.time__details p {
		line-height: 5rem;
	}
}
@media (min-width: 1024px) {
	.appointment--opacity {
		width: 60vw;
		transform: translateY(4rem);
	}
}
