.care {
	position: relative;
}
.care__global {
	margin: 0 2rem;
}
.care__products {
	margin: 2rem 0;
	border-radius: 1rem;
	background: #7b6766;
	color: #fff;
	padding: 1rem;
}
.care .products__dontuse p {
	text-align: left;
}
.care .products__dontuse ul {
	-webkit-columns: 2;
	-moz-columns: 2;
	columns: 2;
}
.care .products__dontuse li {
	margin: 0;
}
.care__title {
	margin-bottom: 2rem;
	padding: 2rem 0;
	background-color: #1e0c08;
	color: #fff;
}
.care em {
	font-weight: bold;
}
.care ul {
	text-align: left;
}
.care ul li {
	margin: 0.5rem 0;
}
.care__products--danger {
	color: #000;
	margin: 0 0.5rem;
}
.products__dontuse li {
	margin: 0 1rem;
}
@media (min-width: 425px) {
	.care__products {
		margin: 2rem;
	}
}
@media (min-width: 1024px) {
	.care {
		padding-bottom: 5rem;
	}
	.care__global {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin: 0 4rem;
	}
	.care__pretattoo,
	.care__posttattoo {
		width: 50%;
	}
	.care__pretattoo {
		padding-right: 2rem;
	}
	.care__posttattoo {
		padding-left: 2rem;
	}
	.care__products {
		width: 40vw;
		position: absolute;
		bottom: 0;
		left: 2rem;
		border-radius: 1rem;
		background: #7b6766;
		z-index: 100;
		color: #fff;
		padding: 0.5rem 1rem;
	}
	.care .products__text {
		position: relative;
	}
	.care .products__text p,
	.care .products__text li {
		font-size: 0.8rem;
	}
	.care .products__text .h3--title {
		font-size: 1.15rem;
	}
	.care .products__text li {
		margin: 0;
	}
	.care .products__text h3 {
		margin-bottom: 0.5rem;
	}
	.care .products__dontuse {
		margin-top: 0.5rem;
	}
	.care .products__dontuse li {
		margin: 0 1rem;
	}
}
@media (min-width: 1200px) {
	.care__products {
		padding: 1rem 2rem;
	}
}
@media (min-width: 1440px) {
	.care {
		padding-bottom: 8rem;
	}
}
