* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
a {
	color: #000;
	text-decoration: none;
}
li {
	list-style: none;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
