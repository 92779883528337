#contact {
	/* height: 100vh; */
	position: relative;
	margin: 0 2rem;
}
.contact__email {
	margin: 2rem auto;
}
.contact__email p:nth-child(1) {
	font-size: 1.1rem;
}
.contact__email p:nth-child(2) {
	font-weight: bold;
}
.contact__location iframe {
	height: 23.1rem;
	width: 100%;
	border: none;
}
input,
textarea {
	width: 100%;
	border-radius: 0.2rem;
	font-size: 1.1rem;
	border: solid 1px;
	padding: 5px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
}
input::placeholder,
textarea::placeholder {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
}
form .control {
	margin: 0.7rem 0;
}
form label {
	display: block;
}
form input {
	height: 2.5rem;
}
form textarea {
	height: 6.9rem;
}
form #send {
	background: lightgray;
	color: #000;
}
.contact__messagesent,
.contact__messageunsent {
	width: 80vw;
	margin: 0.5rem auto;
}
.contact__invalid,
.contact__messageunsent,
.contact__messagesent {
	color: #fff;
	border-radius: 0.2rem;
	padding: 0.5rem;
}
.contact__messageunsent,
.contact__invalid {
	background: #b31919;
}
.contact__messagesent {
	background: #034f22;
}
.contact__messageunsent a {
	color: #fff;
}
#send {
	cursor: pointer;
}
/*style des images*/
.contact__img {
	height: 5rem;
	width: 5rem;
	margin: 0.5rem;
}
/* .contact__img--container img {
	height: 100%;
} */
/*fin stye images*/
.flex__input {
	display: flex;
}
.flex__input button {
	background: lightgray;
	color: #000;
	border-radius: 0.2rem;
	border: solid 1px;
	font-size: 1.1rem;
	padding: 0 0.3rem;
}
.flex__input input {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
	border: solid 1px #000;
}
@media (min-width: 425px) {
	.contact__location p {
		display: none;
	}
}
@media (min-width: 768px) {
	.contact__messagesent {
		width: 30vw;
	}
	.contact__flex {
		margin: 0 5rem;
	}
}
@media (min-width: 1024px) {
	/*style temporaire*/
	/* .contact__email p:nth-child(1) {
		font-size: 1.5rem;
	} */
	/*fin style temporaire*/
	#contact {
		height: 100vh;
	}
	.contact__form {
		position: absolute;
		width: 100%;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.contact__flex {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		/* position: absolute;
		width: 100%;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%); */
		margin: 0;
	}
	form,
	.contact__location {
		width: 40%;
		/* width: 60%; */
	}
	.form__fullname {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.form__fullname .control {
		width: 49%;
		margin: initial;
	}
	.contact__location {
		margin-left: 1rem;
		/* margin: 1rem auto; */
	}
	form {
		margin-right: 1rem;
	}
	.contact__img {
		height: 7rem;
		width: 7rem;
	}
	.contact__img--uploaded {
		margin-top: 2rem;
	}
}
