.gallery__container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.gallery__img--container {
	width: 9rem;
	margin: 0.5rem;
}
.gallery__img--container img {
	width: 100%;
}
@media (min-width: 425px) {
	.gallery__img--container {
		width: 11rem;
	}
}
@media (min-width: 768px) {
	.gallery__img--container {
		width: 15rem;
	}
}
@media (min-width: 900px) {
	.gallery__img--container {
		width: 19rem;
	}
}
@media (min-width: 1200px) {
	.gallery__img--container {
		width: 22.5rem;
	}
}
@media (min-width: 1440px) {
	.gallery__container {
		margin: 0 5rem;
	}
}
