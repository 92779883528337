.header__section {
	position: relative;
	height: 100vh;
}
header {
	width: 100%;
	position: fixed;
	top: 0;
	background-color: rgba(4, 3, 3, 0.9);
	padding-top: 1rem;
	z-index: 800;
}
.header__section img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}
header nav li {
	font-family: "Philosopher", sans-serif;
}
header nav a {
	color: #fff;
}
@media (max-width: 424px) {
	header {
		transform: translateY(-100%);
		transition: transform ease-out 0.5s;
	}
	header.displayed {
		transform: translateY(0);
		transition: transform ease-out 0.5s 0.2s;
	}
	.header__btn {
		position: fixed;
		top: 0.625rem;
		z-index: 1000;
		transition: transform ease-out 0.5s 0.2s;
	}
	.header__btn.hidden {
		transform: translateX(-100%);
		transition: transform ease-out 0.5s;
	}
	.header__btn button {
		background: #fff;
		padding: 0.3rem;
		color: #000;
		border-left: none;
		border-right: solid 1px;
		border-top: solid 1px;
		border-bottom: solid 1px;
		border-radius: 0 5px 5px 0;
	}
	header nav {
		position: relative;
	}
	.header__close--menu {
		color: #fff;
		position: absolute;
		left: 1rem;
		top: -1rem;
	}
	header ul li {
		padding: 0.4rem 0;
	}
	header nav a {
		border-bottom: solid 1px #fff;
	}
	.header__section--svg {
		display: none;
	}
}
@media (min-width: 425px) {
	header {
		transition: transform 0.5s;
	}
	header.nav__hidden {
		transform: translateY(-100%);
		transition: transform 0.5s;
	}
	header nav {
		padding: 1rem 0;
	}
	.header__close--menu,
	.header__btn {
		display: none;
	}
	header p {
		color: #000;
		width: 20vw;
		background: #ede9e8;
		margin: 0 auto;
		transform: translateY(100%);
		border-radius: 0 0 25px 25px;
		padding: 0.2rem 0;
	}
	header ul {
		display: flex;
		justify-content: space-around;
	}
	header .menu__displayed {
		opacity: 1;
	}
	header .menu__hidden {
		opacity: 0;
	}
	.header__section--svg {
		position: absolute;
		bottom: 0;
		height: 4rem;
		left: 50%;
		transform: translateX(-50%);
		transition: opacity 0.5s;
	}
	.svg__hidden {
		opacity: 0;
		transition: opacity 0.5s;
	}
	.header__section--svg svg {
		display: block;
		width: 1.5rem;
		animation: moveDown 0.8s ease infinite alternate;
	}
	.header__section--svg svg:nth-child(1) {
		transform: translateY(0.8rem);
		animation: moveDownBis 0.8s infinite alternate;
	}
}

@keyframes moveDown {
	0% {
		transform: translateY(0.1rem);
	}
	100% {
		transform: translateY(0.6rem);
	}
}
@keyframes moveDownBis {
	0% {
		transform: translateY(0.8rem);
	}
	100% {
		transform: translateY(1.3rem);
	}
}
