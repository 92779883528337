@import url("https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap");
.App {
	text-align: center;
}
.h2--title {
	font-size: 1.5rem;
	font-family: "Philosopher", sans-serif;
}
.h3--title {
	font-size: 1.15rem;
	font-weight: bold;
}
p,
li {
	font-size: 0.9rem;
}

@media (min-width: 768px) {
	.section__separate {
		height: 5rem;
		background-color: transparent;
	}
	.h2--title {
		font-size: 2rem;
	}
}

@media (min-width: 1024px) {
	p,
	li {
		font-size: 1rem;
	}
	h2 {
		font-size: 2rem;
	}
}
@media (min-width: 1200px) {
	p,
	li {
		font-size: 1.1rem;
	}
}
@media (min-width: 1440px) {
	p,
	li {
		font-size: 1.2rem;
	}
}
