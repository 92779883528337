.infos {
	height: 100vh;
	position: relative;
	background-color: #1e0c08;
	color: #fff;
}
.warning__text {
	background: #31211e;
}
.warning__text p,
.warning__text ul {
	margin: 2rem;
}
@media (max-width: 767px) {
	.infos .warning__text {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.infos .warning__img {
		display: none;
	}
}
@media (min-width: 768px) {
	.infos .warning {
		display: flex;
		justify-content: center;
		height: 100vh;
	}
	/* .infos .warning__text {
		align-self: center;
		padding: 2rem;
		text-align: left;
	} */
	.infos .warning__text {
		position: absolute;
		left: 15%;
		top: 50%;
		width: 30rem;
		transform: translateY(-50%);
		background: rgba(49, 33, 30, 0.7);
		padding: 2rem;
		text-align: left;
	}
	.infos .warning__background {
		width: 50%;
		position: relative;
	}
	.infos .warning__background,
	.infos .warning__img {
		width: 50%;
	}
	.warning__text ul {
		margin-bottom: 2rem;
	}
	.warning__text ul p {
		margin: 0;
	}
	.infos .warning__img {
		display: block;
	}
	.infos .warning__img img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
@media (min-width: 1024px) {
	.infos .warning__text {
		left: 20%;
		width: 40rem;
		padding: 2rem 4rem;
	}
}
@media (min-width: 1440px) {
	.infos .warning__text {
		left: 25%;
		width: 45rem;
		padding: 2rem 6rem;
	}
}
