.steps {
	margin: 0 2rem;
}
.steps .h3--title {
	color: #6a2702;
}
.single__step--bold {
	font-weight: bold;
}
.frise__container {
	width: 60vw;
	margin: 1rem auto;
}
.frise {
	position: relative;
	border: solid 1px;
	background-color: #ede9e8;
}
.frise__text {
	height: 6rem;
}
.frise__text p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-40%, -50%);
	font-family: "Philosopher", sans-serif;
}
.frise__arrow {
	position: absolute;
	height: 0;
	width: 0;
	z-index: 500;
}
.single__step {
	margin: 1rem 0;
}
.single__step p {
	margin: 1rem auto;
}
.single__step ul {
	text-align: left;
	margin: 1rem 0;
}
.steps__cancel {
	color: #a30500;
	margin-top: 1rem;
}

@media (max-width: 767px) {
	.frise {
		border-left: solid 1px;
		border-right: solid 1px;
	}
	.frise:nth-child(1) {
		border-top: solid 1px;
	}
	.frise:nth-last-child(-n + 1) {
		border-bottom: solid 1px;
	}
	.frise__arrow {
		position: absolute;
		height: 0;
		width: 0;
		z-index: 500;
		border-right: 30vw solid transparent;
		border-top: 10px solid #000;
		border-left: 30vw solid transparent;
		bottom: -12px;
		left: 50%;
		transform: translateX(-50%);
	}
	.arrow__inner {
		border-top: 10px solid #ede9e8;
		right: 0.1rem;
		bottom: 0.1rem;
	}
	.frise__text p {
		transform: translate(-50%, -20%);
	}
	.single__step {
		margin: 1rem auto;
	}
}
@media (min-width: 768px) {
	.frise__container {
		display: flex;
		justify-content: center;
		width: 88vw;
	}
	.frise {
		width: 100%;
	}
	.frise__arrow {
		right: -1.1rem;
		border-top: 3.1rem solid transparent;
		border-bottom: 3.1rem solid transparent;
		border-left: 1rem solid #000;
		top: 0;
	}
	.arrow__inner {
		border-left: 1rem solid #ede9e8;
		right: 0.1rem;
		top: -3.1rem;
	}
	.step__bloc--flex {
		display: flex;
		justify-content: space-between;
	}
	.step__bloc--flex > div {
		width: 50%;
	}
	.single__step--flex {
		display: flex;
		text-align: left;
	}
	.single__step--flex li {
		margin: 1rem 0;
	}
	.single__step--flex li p {
		margin: 0.5rem 1.5rem;
	}
	.single__step ul {
		text-align: left;
		margin: 1rem 0 0 1rem;
	}
	.single__step .steps--margin {
		margin: 1rem 0 0 1rem;
	}
}
@media (min-width: 1024px) {
	.steps {
		margin: 0 4rem;
	}
	.step__bloc--flex > div {
		width: 45%;
		text-align: left;
	}
}
@media (min-width: 1300px) {
	.frise__container {
		margin: 1rem auto 4rem auto;
	}
}
