.about .text__container p {
	margin: 2rem 0;
}
@media (max-width: 767px) {
	.about .text__container {
		margin: 0 2rem;
	}
	.about .img__container {
		display: none;
	}
}
@media (min-width: 768px) {
	.about__flex {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.about__flex {
		margin: 0 2rem;
	}
	.about .text__container {
		padding-right: 2rem;
	}
	.about .text__container {
		width: 60%;
	}
	.about .img__container {
		width: 40%;
	}
	.about .text__container {
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: left;
	}
	.about .img__container {
		display: block;
		height: 75vh;
	}
	.about .img__container img {
		height: 100%;
		width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}
@media (min-width: 1024px) {
	.about {
		height: 100vh;
		position: relative;
	}
	.about__flex {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		margin: 0 4rem;
	}
	.about .text__container {
		padding-right: 5rem;
	}
	.about .text__container p {
		margin: 3rem 0;
	}
}
@media (min-width: 1440px) {
	.about__flex {
		margin: 0 6rem;
	}
}
