@import url(https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap);
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
a {
	color: #000;
	text-decoration: none;
}
li {
	list-style: none;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
	text-align: center;
}
.h2--title {
	font-size: 1.5rem;
	font-family: "Philosopher", sans-serif;
}
.h3--title {
	font-size: 1.15rem;
	font-weight: bold;
}
p,
li {
	font-size: 0.9rem;
}

@media (min-width: 768px) {
	.section__separate {
		height: 5rem;
		background-color: transparent;
	}
	.h2--title {
		font-size: 2rem;
	}
}

@media (min-width: 1024px) {
	p,
	li {
		font-size: 1rem;
	}
	h2 {
		font-size: 2rem;
	}
}
@media (min-width: 1200px) {
	p,
	li {
		font-size: 1.1rem;
	}
}
@media (min-width: 1440px) {
	p,
	li {
		font-size: 1.2rem;
	}
}

.landing {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.landing h1 {
	font-weight: initial;
	margin: 4rem auto;
	font-size: 3rem;
}

.landing button {
	font-size: 2rem;
	border: solid #808080 1px;
	background: none;
	padding: 0.2rem 2rem;
	margin: 1rem;
	border-radius: 0.3em;
}
.about .text__container p {
	margin: 2rem 0;
}
@media (max-width: 767px) {
	.about .text__container {
		margin: 0 2rem;
	}
	.about .img__container {
		display: none;
	}
}
@media (min-width: 768px) {
	.about__flex {
		display: flex;
	}
	.about__flex {
		margin: 0 2rem;
	}
	.about .text__container {
		padding-right: 2rem;
	}
	.about .text__container {
		width: 60%;
	}
	.about .img__container {
		width: 40%;
	}
	.about .text__container {
		align-self: center;
		text-align: left;
	}
	.about .img__container {
		display: block;
		height: 75vh;
	}
	.about .img__container img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
@media (min-width: 1024px) {
	.about {
		height: 100vh;
		position: relative;
	}
	.about__flex {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin: 0 4rem;
	}
	.about .text__container {
		padding-right: 5rem;
	}
	.about .text__container p {
		margin: 3rem 0;
	}
}
@media (min-width: 1440px) {
	.about__flex {
		margin: 0 6rem;
	}
}

#appointment {
	color: #fff;
	position: relative;
}
.appointment--background {
	position: absolute;
	z-index: -10;
	height: 100%;
	width: 100%;
}
.appointment--background img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 35%;
}
#appointment p {
	margin: 0.5rem 0;
}
.appointment__time,
.appointment__price {
	position: relative;
	margin: 2rem;
}
.appointment--opacity {
	background-color: rgba(4, 3, 3, 0.6);
	padding: 2rem 0;
}
#appointment .time__details p {
	margin: 1rem 0;
	line-height: 2rem;
}
.appointment__location iframe {
	width: 90vw;
	height: 300px;
}

#appointment .price__details {
	margin: 2rem auto;
}
@media (max-width: 767px) {
	#appointment {
		margin: 3rem 0;
	}
}
@media (min-width: 768px) {
	#appointment {
		height: 100vh;
		font-size: 1.5rem;
	}
	.appointment--opacity {
		width: 80vw;
		transform: translateY(2rem);
		margin: 0 auto;
		background-color: rgba(4, 3, 3, 0.8);
	}
	.appointment__location iframe {
		width: 60vw;
		height: 400px;
	}
	.time__details,
	.price__details {
		display: flex;
		justify-content: space-around;
	}
	.time__details p {
		line-height: 5rem;
	}
}
@media (min-width: 1024px) {
	.appointment--opacity {
		width: 60vw;
		transform: translateY(4rem);
	}
}

.care {
	position: relative;
}
.care__global {
	margin: 0 2rem;
}
.care__products {
	margin: 2rem 0;
	border-radius: 1rem;
	background: #7b6766;
	color: #fff;
	padding: 1rem;
}
.care .products__dontuse p {
	text-align: left;
}
.care .products__dontuse ul {
	columns: 2;
}
.care .products__dontuse li {
	margin: 0;
}
.care__title {
	margin-bottom: 2rem;
	padding: 2rem 0;
	background-color: #1e0c08;
	color: #fff;
}
.care em {
	font-weight: bold;
}
.care ul {
	text-align: left;
}
.care ul li {
	margin: 0.5rem 0;
}
.care__products--danger {
	color: #000;
	margin: 0 0.5rem;
}
.products__dontuse li {
	margin: 0 1rem;
}
@media (min-width: 425px) {
	.care__products {
		margin: 2rem;
	}
}
@media (min-width: 1024px) {
	.care {
		padding-bottom: 5rem;
	}
	.care__global {
		display: flex;
		margin: 0 4rem;
	}
	.care__pretattoo,
	.care__posttattoo {
		width: 50%;
	}
	.care__pretattoo {
		padding-right: 2rem;
	}
	.care__posttattoo {
		padding-left: 2rem;
	}
	.care__products {
		width: 40vw;
		position: absolute;
		bottom: 0;
		left: 2rem;
		border-radius: 1rem;
		background: #7b6766;
		z-index: 100;
		color: #fff;
		padding: 0.5rem 1rem;
	}
	.care .products__text {
		position: relative;
	}
	.care .products__text p,
	.care .products__text li {
		font-size: 0.8rem;
	}
	.care .products__text .h3--title {
		font-size: 1.15rem;
	}
	.care .products__text li {
		margin: 0;
	}
	.care .products__text h3 {
		margin-bottom: 0.5rem;
	}
	.care .products__dontuse {
		margin-top: 0.5rem;
	}
	.care .products__dontuse li {
		margin: 0 1rem;
	}
}
@media (min-width: 1200px) {
	.care__products {
		padding: 1rem 2rem;
	}
}
@media (min-width: 1440px) {
	.care {
		padding-bottom: 8rem;
	}
}

#contact {
	/* height: 100vh; */
	position: relative;
	margin: 0 2rem;
}
.contact__email {
	margin: 2rem auto;
}
.contact__email p:nth-child(1) {
	font-size: 1.1rem;
}
.contact__email p:nth-child(2) {
	font-weight: bold;
}
.contact__location iframe {
	height: 23.1rem;
	width: 100%;
	border: none;
}
input,
textarea {
	width: 100%;
	border-radius: 0.2rem;
	font-size: 1.1rem;
	border: solid 1px;
	padding: 5px;
}
input::placeholder,
textarea::placeholder {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
}
form .control {
	margin: 0.7rem 0;
}
form label {
	display: block;
}
form input {
	height: 2.5rem;
}
form textarea {
	height: 6.9rem;
}
form #send {
	background: lightgray;
	color: #000;
}
.contact__messagesent,
.contact__messageunsent {
	width: 80vw;
	margin: 0.5rem auto;
}
.contact__invalid,
.contact__messageunsent,
.contact__messagesent {
	color: #fff;
	border-radius: 0.2rem;
	padding: 0.5rem;
}
.contact__messageunsent,
.contact__invalid {
	background: #b31919;
}
.contact__messagesent {
	background: #034f22;
}
.contact__messageunsent a {
	color: #fff;
}
#send {
	cursor: pointer;
}
/*style des images*/
.contact__img {
	height: 5rem;
	width: 5rem;
	margin: 0.5rem;
}
/* .contact__img--container img {
	height: 100%;
} */
/*fin stye images*/
.flex__input {
	display: flex;
}
.flex__input button {
	background: lightgray;
	color: #000;
	border-radius: 0.2rem;
	border: solid 1px;
	font-size: 1.1rem;
	padding: 0 0.3rem;
}
.flex__input input {
	color: #767676;
	font-family: Georgia, "Times New Roman", Times, serif;
	border: solid 1px #000;
}
@media (min-width: 425px) {
	.contact__location p {
		display: none;
	}
}
@media (min-width: 768px) {
	.contact__messagesent {
		width: 30vw;
	}
	.contact__flex {
		margin: 0 5rem;
	}
}
@media (min-width: 1024px) {
	/*style temporaire*/
	/* .contact__email p:nth-child(1) {
		font-size: 1.5rem;
	} */
	/*fin style temporaire*/
	#contact {
		height: 100vh;
	}
	.contact__form {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}
	.contact__flex {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		/* position: absolute;
		width: 100%;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%); */
		margin: 0;
	}
	form,
	.contact__location {
		width: 40%;
		/* width: 60%; */
	}
	.form__fullname {
		display: flex;
		justify-content: space-between;
	}
	.form__fullname .control {
		width: 49%;
		margin: initial;
	}
	.contact__location {
		margin-left: 1rem;
		/* margin: 1rem auto; */
	}
	form {
		margin-right: 1rem;
	}
	.contact__img {
		height: 7rem;
		width: 7rem;
	}
	.contact__img--uploaded {
		margin-top: 2rem;
	}
}

.footer {
	margin: 2rem 2rem 1rem 2rem;
}
.footer__flex {
	display: flex;
	justify-content: space-between;
}

@media (min-width: 1024px) {
	.footer {
		margin: 0 4rem 1rem 4rem;
	}
	.footer a {
		position: relative;
		overflow: hidden;
		display: inline-block;
	}
	.footer .footer__underline {
		position: absolute;
		left: -100%;
		bottom: -4px;
		border: solid;
		width: 100%;
		animation: underline_out 0.5s ease forwards;
	}
	.footer a:hover .footer__underline {
		animation: underline_in 0.5s ease forwards;
	}
}

@keyframes underline_in {
	0% {
		left: -100%;
	}
	100% {
		left: 0;
	}
}
@keyframes underline_out {
	0% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}

.gallery__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.gallery__img--container {
	width: 9rem;
	margin: 0.5rem;
}
.gallery__img--container img {
	width: 100%;
}
@media (min-width: 425px) {
	.gallery__img--container {
		width: 11rem;
	}
}
@media (min-width: 768px) {
	.gallery__img--container {
		width: 15rem;
	}
}
@media (min-width: 900px) {
	.gallery__img--container {
		width: 19rem;
	}
}
@media (min-width: 1200px) {
	.gallery__img--container {
		width: 22.5rem;
	}
}
@media (min-width: 1440px) {
	.gallery__container {
		margin: 0 5rem;
	}
}

.header__section {
	position: relative;
	height: 100vh;
}
header {
	width: 100%;
	position: fixed;
	top: 0;
	background-color: rgba(4, 3, 3, 0.9);
	padding-top: 1rem;
	z-index: 800;
}
.header__section img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}
header nav li {
	font-family: "Philosopher", sans-serif;
}
header nav a {
	color: #fff;
}
@media (max-width: 424px) {
	header {
		transform: translateY(-100%);
		transition: transform ease-out 0.5s;
	}
	header.displayed {
		transform: translateY(0);
		transition: transform ease-out 0.5s 0.2s;
	}
	.header__btn {
		position: fixed;
		top: 0.625rem;
		z-index: 1000;
		transition: transform ease-out 0.5s 0.2s;
	}
	.header__btn.hidden {
		transform: translateX(-100%);
		transition: transform ease-out 0.5s;
	}
	.header__btn button {
		background: #fff;
		padding: 0.3rem;
		color: #000;
		border-left: none;
		border-right: solid 1px;
		border-top: solid 1px;
		border-bottom: solid 1px;
		border-radius: 0 5px 5px 0;
	}
	header nav {
		position: relative;
	}
	.header__close--menu {
		color: #fff;
		position: absolute;
		left: 1rem;
		top: -1rem;
	}
	header ul li {
		padding: 0.4rem 0;
	}
	header nav a {
		border-bottom: solid 1px #fff;
	}
	.header__section--svg {
		display: none;
	}
}
@media (min-width: 425px) {
	header {
		transition: transform 0.5s;
	}
	header.nav__hidden {
		transform: translateY(-100%);
		transition: transform 0.5s;
	}
	header nav {
		padding: 1rem 0;
	}
	.header__close--menu,
	.header__btn {
		display: none;
	}
	header p {
		color: #000;
		width: 20vw;
		background: #ede9e8;
		margin: 0 auto;
		transform: translateY(100%);
		border-radius: 0 0 25px 25px;
		padding: 0.2rem 0;
	}
	header ul {
		display: flex;
		justify-content: space-around;
	}
	header .menu__displayed {
		opacity: 1;
	}
	header .menu__hidden {
		opacity: 0;
	}
	.header__section--svg {
		position: absolute;
		bottom: 0;
		height: 4rem;
		left: 50%;
		transform: translateX(-50%);
		transition: opacity 0.5s;
	}
	.svg__hidden {
		opacity: 0;
		transition: opacity 0.5s;
	}
	.header__section--svg svg {
		display: block;
		width: 1.5rem;
		animation: moveDown 0.8s ease infinite alternate;
	}
	.header__section--svg svg:nth-child(1) {
		transform: translateY(0.8rem);
		animation: moveDownBis 0.8s infinite alternate;
	}
}

@keyframes moveDown {
	0% {
		transform: translateY(0.1rem);
	}
	100% {
		transform: translateY(0.6rem);
	}
}
@keyframes moveDownBis {
	0% {
		transform: translateY(0.8rem);
	}
	100% {
		transform: translateY(1.3rem);
	}
}

.infos {
	height: 100vh;
	position: relative;
	background-color: #1e0c08;
	color: #fff;
}
.warning__text {
	background: #31211e;
}
.warning__text p,
.warning__text ul {
	margin: 2rem;
}
@media (max-width: 767px) {
	.infos .warning__text {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.infos .warning__img {
		display: none;
	}
}
@media (min-width: 768px) {
	.infos .warning {
		display: flex;
		justify-content: center;
		height: 100vh;
	}
	/* .infos .warning__text {
		align-self: center;
		padding: 2rem;
		text-align: left;
	} */
	.infos .warning__text {
		position: absolute;
		left: 15%;
		top: 50%;
		width: 30rem;
		transform: translateY(-50%);
		background: rgba(49, 33, 30, 0.7);
		padding: 2rem;
		text-align: left;
	}
	.infos .warning__background {
		width: 50%;
		position: relative;
	}
	.infos .warning__background,
	.infos .warning__img {
		width: 50%;
	}
	.warning__text ul {
		margin-bottom: 2rem;
	}
	.warning__text ul p {
		margin: 0;
	}
	.infos .warning__img {
		display: block;
	}
	.infos .warning__img img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
@media (min-width: 1024px) {
	.infos .warning__text {
		left: 20%;
		width: 40rem;
		padding: 2rem 4rem;
	}
}
@media (min-width: 1440px) {
	.infos .warning__text {
		left: 25%;
		width: 45rem;
		padding: 2rem 6rem;
	}
}

.steps {
	margin: 0 2rem;
}
.steps .h3--title {
	color: #6a2702;
}
.single__step--bold {
	font-weight: bold;
}
.frise__container {
	width: 60vw;
	margin: 1rem auto;
}
.frise {
	position: relative;
	border: solid 1px;
	background-color: #ede9e8;
}
.frise__text {
	height: 6rem;
}
.frise__text p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-40%, -50%);
	font-family: "Philosopher", sans-serif;
}
.frise__arrow {
	position: absolute;
	height: 0;
	width: 0;
	z-index: 500;
}
.single__step {
	margin: 1rem 0;
}
.single__step p {
	margin: 1rem auto;
}
.single__step ul {
	text-align: left;
	margin: 1rem 0;
}
.steps__cancel {
	color: #a30500;
	margin-top: 1rem;
}

@media (max-width: 767px) {
	.frise {
		border-left: solid 1px;
		border-right: solid 1px;
	}
	.frise:nth-child(1) {
		border-top: solid 1px;
	}
	.frise:nth-last-child(-n + 1) {
		border-bottom: solid 1px;
	}
	.frise__arrow {
		position: absolute;
		height: 0;
		width: 0;
		z-index: 500;
		border-right: 30vw solid transparent;
		border-top: 10px solid #000;
		border-left: 30vw solid transparent;
		bottom: -12px;
		left: 50%;
		transform: translateX(-50%);
	}
	.arrow__inner {
		border-top: 10px solid #ede9e8;
		right: 0.1rem;
		bottom: 0.1rem;
	}
	.frise__text p {
		transform: translate(-50%, -20%);
	}
	.single__step {
		margin: 1rem auto;
	}
}
@media (min-width: 768px) {
	.frise__container {
		display: flex;
		justify-content: center;
		width: 88vw;
	}
	.frise {
		width: 100%;
	}
	.frise__arrow {
		right: -1.1rem;
		border-top: 3.1rem solid transparent;
		border-bottom: 3.1rem solid transparent;
		border-left: 1rem solid #000;
		top: 0;
	}
	.arrow__inner {
		border-left: 1rem solid #ede9e8;
		right: 0.1rem;
		top: -3.1rem;
	}
	.step__bloc--flex {
		display: flex;
		justify-content: space-between;
	}
	.step__bloc--flex > div {
		width: 50%;
	}
	.single__step--flex {
		display: flex;
		text-align: left;
	}
	.single__step--flex li {
		margin: 1rem 0;
	}
	.single__step--flex li p {
		margin: 0.5rem 1.5rem;
	}
	.single__step ul {
		text-align: left;
		margin: 1rem 0 0 1rem;
	}
	.single__step .steps--margin {
		margin: 1rem 0 0 1rem;
	}
}
@media (min-width: 1024px) {
	.steps {
		margin: 0 4rem;
	}
	.step__bloc--flex > div {
		width: 45%;
		text-align: left;
	}
}
@media (min-width: 1300px) {
	.frise__container {
		margin: 1rem auto 4rem auto;
	}
}

.legal {
	margin: 4rem 2rem;
}
.legal > a {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 1.2rem;
	padding: 1rem;
}
.legal h1 {
	font-size: 3rem;
	margin: 2rem 0;
	font-family: "Philosopher", sans-serif;
}
.legal h2 {
	margin: 1rem 0;
	font-family: "Philosopher", sans-serif;
}
@media (min-width: 1024px) {
	.legal {
		margin: 4rem;
	}
}

