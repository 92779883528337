.landing {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.landing h1 {
	font-weight: initial;
	margin: 4rem auto;
	font-size: 3rem;
}

.landing button {
	font-size: 2rem;
	border: solid #808080 1px;
	background: none;
	padding: 0.2rem 2rem;
	margin: 1rem;
	border-radius: 0.3em;
}